import React, { useEffect } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { styles } from './success-screen.styles';

export interface Props extends AppWithStyles<typeof styles> {}

const SuccessScreen: React.FC<Props> = ({ classes }) => {
  const SecureSubmittedEvent = new Event('3DS_SUBMITTED');
  // @ts-ignore
  useEffect(async () => {
    window.dispatchEvent(SecureSubmittedEvent);
    window.parent.postMessage('3DS_SUBMITTED', '*');
    // @ts-ignore
    if (window.AndroidApp && window.AndroidApp.submit3DS) {
      // @ts-ignore
      window.AndroidApp.submit3DS('3DS_SUBMITTED');
    }
  }, []);

  return <div className={classes.root}>Payment was successfully completed</div>;
};

export default appWithStyles(styles)(SuccessScreen);
