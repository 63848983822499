import { lazy } from 'react';

import { MaintenancePage } from '@modules/maintenance-page';
import { Activity } from '@modules/new-private/activity';
import { DesktopOrders } from '@modules/new-private/activity/components/orders-list';
import { DesktopWallets } from '@modules/new-private/activity/components/wallets';
import NewDashboard from '@modules/new-private/dashboard';
import Dashboard from '@modules/new-private/dashboard/components/desktop-dashboard';
import { MobileMarket, DesktopMarket } from '@modules/new-private/market';
import { NewTransferDesktop, NewTransferMobile } from '@modules/new-private/orders/new-transfer';
import { MobileSettings, DesktopSettings } from '@modules/new-private/settings';
import { DesktopCards, MobileCards } from '@modules/new-private/settings/components/cards';
import {
  DesktopCertficates,
  MobileCertficates,
} from '@modules/new-private/settings/components/certficates';
import {
  DesktopChangeAvatar,
  MobileChangeAvatar,
} from '@modules/new-private/settings/components/change-avatar';
import { DesktopCurrency, MobileCurrency } from '@modules/new-private/settings/components/currency';
import {
  DesktopDeleteAccount,
  MobileDeleteAccount,
} from '@modules/new-private/settings/components/delete-account';
import {
  MobileDocuments,
  DesktopDocuments,
} from '@modules/new-private/settings/components/documents';
import {
  DesktopHelpCenter,
  MobileHelpCenter,
} from '@modules/new-private/settings/components/help-center';
import { DesktopIssue, MobileIssue } from '@modules/new-private/settings/components/issue';
import {
  MobileNotifications,
  DesktopNotifications,
} from '@modules/new-private/settings/components/notifications';
import {
  DesktopPersonalInfo,
  MobilePersonalInfo,
} from '@modules/new-private/settings/components/personal-info';
import { MobileSecurity, DesktopSecurity } from '@modules/new-private/settings/components/security';
import {
  DesktopChangePassword,
  MobileChangePassword,
} from '@modules/new-private/settings/components/security/components/change-password';
import {
  DesktopChangePhone,
  MobileChangePhone,
} from '@modules/new-private/settings/components/security/components/change-phone';
import { TabbarContainer } from '@modules/new-private/tabbar/tabbar-container';
import { Transactions } from '@modules/new-private/transactions';
import { DesktopTopUp, MobileTopUp } from '@modules/new-private/wallets/top-up';
import PrivateLayout from '@modules/private/layout';
import Welcome from '@modules/private/statics/welcome/welcome';
import Verification from '@modules/private/verification';
import { DeleteAccountResult } from '@modules/public/delete-account';
import Payments3DSecureForm from '@modules/worldpay/3d-secure-form';
import SuccessScreen from '@modules/worldpay/success-screen';
import PageError from '@shared/components/page-error';
import { PageErrorPrivate } from '@shared/components/page-error-private';
import PageNotFound from '@shared/components/page-not-found';
import { createBrowserRouter, Outlet } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { AuthGuard, UnauthGuard, ViewportMobileGuard } from '@shared/guards';
import { ViewportDesktopGuard } from '@shared/guards/viewport-desktop-guard';
import { Navigate } from 'react-router-dom';

import { PurchaseAndSellDesktop, PurchaseAndSellMobile } from 'src/ui/new-private/orders';

import MobilePrivateLayout from '../new-private/layout/privat-layout';
import OnboardingComponent from '../private/onboarding';
import WelcomeScreen from '../private/welcome-screen';
import { Login, Register, PasswordRecovery } from '../public/auth';
import PublicLayout from '../public/layout';

const App = lazy(() => import('../../app'));

export const router = createBrowserRouter([
  {
    path: ROUTES.initial,
    element: <App />,
    errorElement: <PageNotFound />,
    children: [
      {
        path: ROUTES.verification,
        element: (
          <AuthGuard>
            <Verification />
          </AuthGuard>
        ),
      },
      {
        path: ROUTES.mobilePrivate.root,
        element: (
          <ViewportMobileGuard>
            <AuthGuard>
              <MobilePrivateLayout />
            </AuthGuard>
          </ViewportMobileGuard>
        ),
        errorElement: (
          <TabbarContainer>
            <PageErrorPrivate />
          </TabbarContainer>
        ),
        children: [
          {
            path: ROUTES.mobilePrivate.dashboard,
            element: (
              <TabbarContainer>
                <NewDashboard />
              </TabbarContainer>
            ),
          },
          {
            path: ROUTES.mobilePrivate.activity,
            element: (
              <TabbarContainer>
                <Activity />
              </TabbarContainer>
            ),
          },
          {
            path: ROUTES.mobilePrivate.topUp,
            element: <MobileTopUp />,
          },
          {
            path: ROUTES.mobilePrivate.maintenancePage,
            element: <MaintenancePage />,
          },
          {
            path: ROUTES.mobilePrivate.transactions,
            element: <Transactions />,
          },
          {
            path: ROUTES.mobilePrivate.market,
            element: (
              <TabbarContainer>
                <MobileMarket />
              </TabbarContainer>
            ),
          },
          {
            path: ROUTES.mobilePrivate.settings.root,
            element: <Outlet />,
            errorElement: (
              <TabbarContainer>
                <PageErrorPrivate />
              </TabbarContainer>
            ),
            children: [
              {
                index: true,
                element: (
                  <TabbarContainer>
                    <MobileSettings />
                  </TabbarContainer>
                ),
              },
              {
                path: ROUTES.mobilePrivate.settings.personalInfo,
                element: <MobilePersonalInfo />,
              },
              {
                path: ROUTES.mobilePrivate.settings.changeAvatar,
                element: <MobileChangeAvatar />,
              },
              {
                path: ROUTES.mobilePrivate.settings.certificates,
                element: <MobileCertficates />,
              },
              {
                path: ROUTES.mobilePrivate.settings.documents,
                element: <MobileDocuments />,
              },
              {
                path: ROUTES.mobilePrivate.settings.security.root,
                element: <Outlet />,
                errorElement: (
                  <TabbarContainer>
                    <PageErrorPrivate />
                  </TabbarContainer>
                ),
                children: [
                  {
                    index: true,
                    element: <MobileSecurity />,
                  },
                  {
                    path: ROUTES.mobilePrivate.settings.security.phone,
                    element: <MobileChangePhone />,
                  },
                  {
                    path: ROUTES.mobilePrivate.settings.security.changePassword,
                    element: <MobileChangePassword />,
                  },
                ],
              },
              {
                path: ROUTES.mobilePrivate.settings.cards,
                element: <MobileCards />,
              },
              {
                path: ROUTES.mobilePrivate.settings.currency,
                element: <MobileCurrency />,
              },
              {
                path: ROUTES.mobilePrivate.settings.notifications,
                element: <MobileNotifications />,
              },
              {
                path: ROUTES.mobilePrivate.settings.deleteAccount,
                element: <MobileDeleteAccount />,
              },
              {
                path: ROUTES.mobilePrivate.settings.helpCenter.root,
                children: [
                  {
                    index: true,
                    element: <MobileHelpCenter />,
                  },
                  {
                    path: ROUTES.mobilePrivate.settings.helpCenter.issue(),
                    element: <MobileIssue />,
                  },
                ],
              },
            ],
          },
          {
            path: ROUTES.mobilePrivate.transfer,
            element: <NewTransferMobile />,
          },
          {
            path: ROUTES.mobilePrivate.wallets.root,
            element: <Outlet />,
            errorElement: (
              <TabbarContainer>
                <PageErrorPrivate />
              </TabbarContainer>
            ),
            children: [
              {
                index: true,
                element: <Navigate to={ROUTES.mobilePrivate.activity} />,
              },
              {
                path: ROUTES.mobilePrivate.wallets.children.order(),
                element: <PurchaseAndSellMobile />,
              },
            ],
          },
        ],
      },
      {
        path: ROUTES.desktopPrivate.root,
        element: (
          <ViewportDesktopGuard>
            <AuthGuard>
              <PrivateLayout />
            </AuthGuard>
          </ViewportDesktopGuard>
        ),
        errorElement: <PageErrorPrivate />,
        children: [
          {
            path: ROUTES.desktopPrivate.dashboard,
            element: <Dashboard />,
          },
          {
            path: ROUTES.desktopPrivate.market,
            element: <DesktopMarket />,
          },
          {
            path: ROUTES.desktopPrivate.orders,
            element: <DesktopOrders />,
          },
          {
            path: ROUTES.desktopPrivate.wallets.root,
            element: <Outlet />,
            errorElement: <PageErrorPrivate />,
            children: [
              {
                index: true,
                element: <DesktopWallets />,
              },
              {
                path: ROUTES.desktopPrivate.wallets.children.order(),
                element: <PurchaseAndSellDesktop />,
              },
              {
                path: ROUTES.desktopPrivate.wallets.topUp,
                element: <DesktopTopUp />,
              },
            ],
          },
          {
            path: ROUTES.desktopPrivate.transfers.root,
            element: <Outlet />,
            errorElement: <PageErrorPrivate />,
            children: [
              {
                path: ROUTES.desktopPrivate.transfers.children.new,
                element: <NewTransferDesktop />,
              },
            ],
          },
          {
            path: ROUTES.desktopPrivate.settings.root,
            element: <Outlet />,
            errorElement: <PageErrorPrivate />,
            children: [
              {
                index: true,
                element: <DesktopSettings />,
              },
              {
                path: ROUTES.desktopPrivate.settings.personalInfo,
                element: <DesktopPersonalInfo />,
              },
              {
                path: ROUTES.desktopPrivate.settings.changeAvatar,
                element: <DesktopChangeAvatar />,
              },
              {
                path: ROUTES.desktopPrivate.settings.certificates,
                element: <DesktopCertficates />,
              },
              {
                path: ROUTES.desktopPrivate.settings.documents,
                element: <DesktopDocuments />,
              },
              {
                path: ROUTES.desktopPrivate.settings.security.root,
                element: <Outlet />,
                errorElement: <PageErrorPrivate />,
                children: [
                  {
                    index: true,
                    element: <DesktopSecurity />,
                  },
                  {
                    path: ROUTES.desktopPrivate.settings.security.phone,
                    element: <DesktopChangePhone />,
                  },
                  {
                    path: ROUTES.desktopPrivate.settings.security.changePassword,
                    element: <DesktopChangePassword />,
                  },
                ],
              },
              {
                path: ROUTES.desktopPrivate.settings.cards,
                element: <DesktopCards />,
              },
              {
                path: ROUTES.desktopPrivate.settings.currency,
                element: <DesktopCurrency />,
              },
              {
                path: ROUTES.desktopPrivate.settings.notifications,
                element: <DesktopNotifications />,
              },
              {
                path: ROUTES.desktopPrivate.settings.deleteAccount,
                element: <DesktopDeleteAccount />,
              },
              {
                path: ROUTES.desktopPrivate.settings.helpCenter.root,
                children: [
                  {
                    index: true,
                    element: <DesktopHelpCenter />,
                  },
                  {
                    path: ROUTES.desktopPrivate.settings.helpCenter.issue(),
                    element: <DesktopIssue />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: ROUTES.public.root,
        element: (
          <UnauthGuard>
            <PublicLayout />
          </UnauthGuard>
        ),
        errorElement: <PageError />,
        children: [
          {
            path: ROUTES.public.login(),
            element: <Login />,
          },
          {
            path: ROUTES.public.register,
            element: <Register />,
          },
          {
            path: ROUTES.public.passwordRecovery,
            element: <PasswordRecovery />,
          },
          {
            path: ROUTES.public.deleteAccountResult,
            element: <DeleteAccountResult />,
          },
        ],
      },
      {
        path: ROUTES.static.root,
        element: <PublicLayout />,
        errorElement: <PageError />,
        children: [
          {
            path: ROUTES.static.welcome,
            element: <Welcome />,
          },
        ],
      },
      {
        path: ROUTES.payments3DSercureForm,
        element: <Payments3DSecureForm />,
      },
      {
        path: ROUTES.paymentSuccessScreen,
        element: <SuccessScreen />,
      },
      {
        path: ROUTES.onboarding,
        element: (
          <AuthGuard>
            <PublicLayout />
          </AuthGuard>
        ),
        errorElement: <PageErrorPrivate />,
        children: [
          {
            index: true,
            element: <OnboardingComponent />,
          },
        ],
      },
      {
        path: ROUTES.desktopPrivate.maintenancePage,
        element: (
          <AuthGuard>
            <MaintenancePage />
          </AuthGuard>
        ),
      },
      {
        path: ROUTES.welcomeScreen,
        element: <WelcomeScreen />,
      },
      {
        path: ROUTES.error,
        element: <PageError />,
      },
    ],
  },
]);
