import { appCreateStyles } from '@core/theme/utils/create-styles';

export function styles() {
  return appCreateStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      font: '36px',
    },
  });
}
